<template>
  <div class="examlist-layout">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>考试管理</el-breadcrumb-item>
        <el-breadcrumb-item>专业测试</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div style="display: flex; height: 100%;  padding: 10px 20px;">
      <div class="left">
        <div class="left-top">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-jiagoufenxiao" />
          </svg>
          <span>专业选择</span>
        </div>
        <!--        <Input-->
        <!--          placeholder="输入关键字"-->
        <!--          v-model="filterText">-->
        <!--        </Input>-->
        <el-tree
          node-key="id"
          highlight-current
          @node-click="changePost"
          class="filter-tree"
          :style="{ height: th - 20 + 'px' }"
          :data="treeData"
          :props="defaultProps"
          :filter-node-method="filterNode"
          ref="tree"
        >
          <div class="custom-tree-node" slot-scope="{ node, data }">
            <Tooltip :content="node.label" :transfer="true" max-width="200">
              <div class="desc">
                {{ node.label.length > 15 ? node.label.substr(0, 15) + '...' : node.label }}
              </div>
            </Tooltip>
          </div>
        </el-tree>
      </div>
      <div class="table-container" style="width: calc(100% - 313px);height: 100%;margin-left: 10px;border: 1px solid rgba(7, 27, 41, 0.1);">
        <el-table :data="tableData" height="calc(100% - 53px)" @selection-change="choose" :loading="tLoading">
          <el-table-column type="selection" width="55" />
          <el-table-column prop="name" label="考试名称" show-overflow-tooltip />
          <el-table-column label="发布时间" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ $formatTime(scope.row.createdTime, 'yyyy-MM-dd hh:mm') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="创建者" prop="creatorName" show-overflow-tooltip />
          <el-table-column label="添加时间" show-overflow-tooltip>
            <template slot-scope="scope">
              <span>{{ $formatTime(scope.row.modifyTime, 'yyyy-MM-dd hh:mm') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <Tooltip content="参与考试情况" placement="bottom">
                <Icon
                  type="md-eye"
                  class="icon"
                  @click="
                    $router.push({
                      name: 'postExamDetail',
                      params: { id: scope.row.examId }
                    })
                  "
                />
              </Tooltip>
              <Tooltip content="删除" placement="bottom" style="margin-left: 10px">
                <Icon type="md-trash" class="icon" @click="singleDel(scope.row)" />
              </Tooltip>
            </template>
          </el-table-column>
        </el-table>
        <div class="page">
          <div style="display: flex; flex-direction: row; margin-right: 10px;">
            <Button type="primary" @click="toShowExam">增加</Button>
            <Button type="primary" style="margin-left: 10px" @click="delExam">删除</Button>
          </div>
          <Page
            class="yt-page"
            :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
            :total="total"
            :current="page + 1"
            :page-size-opts="[10, 20, 40, 100]"
            :page-size="size"
            @on-change="changePage"
            @on-page-size-change="changePageSize"
            show-elevator
            show-sizer
            show-total
          />
        </div>
      </div>
    </div>
    <el-dialog :visible="showExam" title="添加测试" class="yt-dialog yt-dialog-default" @close="cancelAdd" @on-cancel="cancelAdd">
      <CheckboxGroup v-model="selectExam" style="overflow: auto;max-height: 400px">
        <Checkbox :label="item.examId" v-for="(item, index) in examList" :key="index">{{ item.name }}</Checkbox>
      </CheckboxGroup>
      <div class="footer" slot="footer">
        <el-button class="button" @click="cancelAdd">取消</el-button>
        <el-button class="button" type="primary" @click="addExam" :loading="loading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import exam from '@api/exam'
import functionApi from '@api/functionApi'
import postExam from '@api/postExam'
export default {
  name: 'Examination',
  data() {
    return {
      tLoading: false,
      chooseExam: [],
      postId: '',
      examList: [],
      selectExam: [],
      loading: false,
      showExam: false,
      th: document.body.clientHeight - 195,
      total: 0,
      page: 0,
      size: 10,
      defaultProps: {
        children: 'posts',
        label: 'name'
      },
      filterText: '',
      treeData: [],
      tableData: []
    }
  },
  created() {
    this.initTreeData()
    this.getExamList()
  },
  methods: {
    toShowExam() {
      postExam.getAddExam(this.postId).then(res => {
        this.examList = res.res
        this.showExam = true
      })
    },
    choose(val) {
      this.chooseExam = val.map(item => {
        return item.examId
      })
    },
    singleDel(row) {
      this.chooseExam = [row.examId]
      this.delExam()
    },
    delExam() {
      if (this.chooseExam.length === 0) {
        this.$message.warning('请选择要删除的测试')
      } else {
        this.$Modal.confirm({
          title: '提示',
          content: '<p>确认删除岗位下所选测试吗？</p>',
          loading: true,
          onOk: () => {
            postExam.delExam(this.postId, this.chooseExam).then(res => {
              if (res) {
                this.$Modal.remove()
                this.$message.success('删除成功')
                this.initData()
                this.chooseExam = []
              } else {
                this.$message.error('删除失败')
              }
            })
          }
        })
      }
    },
    changePost(val) {
      this.postId = val.id
      this.initData()
      this.getExamList()
    },
    getExamList() {
      postExam.getAddExam(this.postId).then(res => {
        this.examList = res.res
      })
    },
    addExam() {
      if (this.selectExam.length === 0 && this.examList.length != 0) {
        this.$message.warning('请选择要添加的测试')
      } else if (this.examList.length === 0) {
        this.$message.warning('没有可添加的测试')
      } else {
        this.loading = true
        postExam
          .addExam(this.postId, this.selectExam)
          .then(res => {
            this.$message.success('添加成功')
            this.initData()
            this.loading = false
            this.cancelAdd()
          })
          .catch(() => {
            this.loading = false
          })
      }
    },
    cancelAdd() {
      this.showExam = false
      this.selectExam = []
    },
    initTreeData() {
      functionApi.getAllPostDataById(0).then(res => {
        this.treeData = res.res
        this.postId = res.res[0].id
        this.getExamList()
        this.$nextTick(() => {
          this.$refs.tree.setCurrentKey(res.res[0].id)
        })
        this.initData()
      })
    },
    initData() {
      this.tLoading = true
      postExam
        .getExam(this.postId, this.page, this.size)
        .then(res => {
          this.tableData = res.res.data
          this.total = res.res.total
          this.tLoading = false
        })
        .catch(() => {
          this.tLoading = false
        })
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    changePage(val) {
      this.page = val - 1
      this.initData()
    },
    changePageSize(val) {
      this.size = val
      this.initData()
    }
  },
  watch: {
    filterText(val) {
      // this.$refs.tree.filter(val)
    }
  }
}
</script>

<style scoped lang="less">
.examlist-layout {
  height: 100%;
  .filter-tree {
    overflow: auto;
    margin-top: 10px;
  }
  .icon {
    font-size: 24px;
    cursor: pointer;
    color: #6a8abe;
  }
  .page {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
}
::v-deep .ivu-checkbox-wrapper {
  font-size: 15px;
  display: flex;
  align-items: center;
  .ivu-checkbox {
    margin-right: 8px;
  }
}
::v-deep .ivu-modal-body {
  padding: 0 20px 10px 20px;
}
::v-deep .ivu-table-header thead tr th {
  background: #fafafa;
  height: 56px;

  span {
    font-size: 16px !important;
    color: #3a4e64;
  }
}
::v-deep .ivu-table-cell-tooltip-content {
  font-size: 14px;
  color: #555555;
}
::v-deep .ivu-table-row {
  height: 56px;
}
.yt-page {
  width: 100%;
  margin-top: 5px;
}
</style>
